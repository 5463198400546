/*
 * Copyright 2024 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

import { LANGUAGES } from 'shared/constants.ts';

/* eslint-env browser */

/**
 * log RUM if part of the sample.
 * @param {string} checkpoint identifies the checkpoint in funnel
 * @param {Object} data additional data for RUM sample
 * @param {string} data.source DOM node that is the source of a checkpoint event,
 * identified by #id or .classname
 * @param {string} data.target subject of the checkpoint event,
 * for instance the href of a link, or a search term
 */
function sampleRUM(checkpoint, data = {}) {
	sampleRUM.baseURL =
		sampleRUM.baseURL ||
		new URL(
			window.RUM_BASE == null ? 'https://rum.hlx.page' : window.RUM_BASE,
			window.location,
		);
	sampleRUM.defer = sampleRUM.defer || [];
	const defer = (fnname) => {
		sampleRUM[fnname] =
			sampleRUM[fnname] ||
			((...args) => sampleRUM.defer.push({ fnname, args }));
	};
	sampleRUM.drain =
		sampleRUM.drain ||
		((dfnname, fn) => {
			sampleRUM[dfnname] = fn;
			sampleRUM.defer
				.filter(({ fnname }) => dfnname === fnname)
				.forEach(({ fnname, args }) => sampleRUM[fnname](...args));
		});
	sampleRUM.always = sampleRUM.always || [];
	sampleRUM.always.on = (chkpnt, fn) => {
		sampleRUM.always[chkpnt] = fn;
	};
	sampleRUM.on = (chkpnt, fn) => {
		sampleRUM.cases[chkpnt] = fn;
	};
	defer('observe');
	defer('cwv');
	try {
		window.hlx = window.hlx || {};
		if (!window.hlx.rum) {
			const usp = new URLSearchParams(window.location.search);
			const weight = usp.get('rum') === 'on' ? 1 : 100; // with parameter, weight is 1. Defaults to 100.
			const id = Math.random().toString(36).slice(-4);
			const random = Math.random();
			const isSelected = random * weight < 1;
			const firstReadTime = window.performance
				? window.performance.timeOrigin
				: Date.now();
			const urlSanitizers = {
				full: () => window.location.href,
				origin: () => window.location.origin,
				path: () => window.location.href.replace(/\?.*$/, ''),
			};
			// eslint-disable-next-line object-curly-newline, max-len
			window.hlx.rum = {
				weight,
				id,
				random,
				isSelected,
				firstReadTime,
				sampleRUM,
				sanitizeURL: urlSanitizers[window.hlx.RUM_MASK_URL || 'path'],
			};
		}

		const { weight, id, firstReadTime } = window.hlx.rum;
		if (window.hlx && window.hlx.rum && window.hlx.rum.isSelected) {
			const knownProperties = [
				'weight',
				'id',
				'referer',
				'checkpoint',
				't',
				'source',
				'target',
				'cwv',
				'CLS',
				'FID',
				'LCP',
				'INP',
				'TTFB',
			];
			const sendPing = (pdata = data) => {
				// eslint-disable-next-line max-len
				const t = Math.round(
					window.performance
						? window.performance.now()
						: Date.now() - firstReadTime,
				);
				// eslint-disable-next-line object-curly-newline, max-len, no-use-before-define
				const body = JSON.stringify(
					{
						weight,
						id,
						referer: window.hlx.rum.sanitizeURL(),
						checkpoint,
						t,
						...data,
					},
					knownProperties,
				);
				const url = new URL(`.rum/${weight}`, sampleRUM.baseURL).href;
				navigator.sendBeacon(url, body);
				// eslint-disable-next-line no-console
				console.debug(`ping:${checkpoint}`, pdata);
			};
			sampleRUM.cases = sampleRUM.cases || {
				cwv: () => sampleRUM.cwv(data) || true,
				lazy: () => {
					// use classic script to avoid CORS issues
					const script = document.createElement('script');
					script.src = new URL(
						'.rum/@adobe/helix-rum-enhancer@^1/src/index.js',
						sampleRUM.baseURL,
					).href;
					document.head.appendChild(script);
					return true;
				},
			};
			sendPing(data);
			if (sampleRUM.cases[checkpoint]) {
				sampleRUM.cases[checkpoint]();
			}
		}
		if (sampleRUM.always[checkpoint]) {
			sampleRUM.always[checkpoint](data);
		}
	} catch (error) {
		// something went wrong
	}
}

/**
 * Setup block utils.
 */
function setup() {
	window.hlx = window.hlx || {};
	window.hlx.RUM_MASK_URL = 'full';
	window.hlx.codeBasePath = '';
	window.hlx.lighthouse =
		new URLSearchParams(window.location.search).get('lighthouse') === 'on';

	const scriptEl = document.querySelector('script[src$="/scripts/scripts.js"]');
	if (scriptEl) {
		try {
			[window.hlx.codeBasePath] = new URL(scriptEl.src).pathname.split(
				'/scripts/scripts.js',
			);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}
}

/**
 * Auto initializiation.
 */

function init() {
	setup();
	sampleRUM('top');

	window.addEventListener('load', () => sampleRUM('load'));

	['error', 'unhandledrejection'].forEach((event) => {
		window.addEventListener(event, ({ reason, error }) => {
			const errData = { source: 'undefined error' };
			try {
				errData.target = (reason || error).toString();
				errData.source = (reason || error).stack
					.split('\n')
					.filter((line) => line.match(/https?:\/\//))
					.shift()
					.replace(/at ([^ ]+) \((.+)\)/, '$1@$2')
					.trim();
			} catch (err) {
				/* error structure was not as expected */
			}
			sampleRUM('error', errData);
		});
	});
}

/**
 * Sanitizes a string for use as class name.
 * @param {string} name The unsanitized string
 * @returns {string} The class name
 */
function toClassName(name) {
	return typeof name === 'string'
		? name
				.toLowerCase()
				.replace(/[^0-9a-z]/gi, '-')
				.replace(/-+/g, '-')
				.replace(/^-|-$/g, '')
		: '';
}

/**
 * Sanitizes a string for use as a js property name.
 * @param {string} name The unsanitized string
 * @returns {string} The camelCased name
 */
function toCamelCase(name) {
	return toClassName(name).replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

/**
 * Extracts the config from a block.
 * @param {Element} block The block element
 * @returns {object} The block config
 */
// eslint-disable-next-line import/prefer-default-export
function readBlockConfig(block) {
	const config = {};
	block.querySelectorAll(':scope > div').forEach((row) => {
		if (row.children) {
			const cols = [...row.children];
			if (cols[1]) {
				const col = cols[1];
				const name = toClassName(cols[0].textContent);
				let value = '';
				if (col.querySelector('a')) {
					const as = [...col.querySelectorAll('a')];
					if (as.length === 1) {
						value = as[0].href;
					} else {
						value = as.map((a) => a.href);
					}
				} else if (col.querySelector('img')) {
					const imgs = [...col.querySelectorAll('img')];
					if (imgs.length === 1) {
						value = imgs[0].src;
					} else {
						value = imgs.map((img) => img.src);
					}
				} else if (col.querySelector('p')) {
					const ps = [...col.querySelectorAll('p')];
					if (ps.length === 1) {
						value = ps[0].textContent;
					} else {
						value = ps.map((p) => p.textContent);
					}
				} else value = row.children[1].textContent;
				config[name] = value;
			}
		}
	});
	return config;
}

/**
 * Loads a CSS file.
 * @param {string} href URL to the CSS file
 */
async function loadCSS(href) {
	return new Promise((resolve, reject) => {
		if (!document.querySelector(`head > link[href="${href}"]`)) {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = href;
			link.onload = resolve;
			link.onerror = reject;
			document.head.append(link);
		} else {
			resolve();
		}
	});
}

/**
 * Loads a non module JS file.
 * @param {string} src URL to the JS file
 * @param {Object} attrs additional optional attributes
 */
async function loadScript(src, attrs) {
	return new Promise((resolve, reject) => {
		if (!document.querySelector(`head > script[src="${src}"]`)) {
			const script = document.createElement('script');
			script.src = src;
			if (attrs) {
				// eslint-disable-next-line no-restricted-syntax, guard-for-in
				for (const attr in attrs) {
					script.setAttribute(attr, attrs[attr]);
				}
			}
			script.onload = resolve;
			script.onerror = reject;
			document.head.append(script);
		} else {
			resolve();
		}
	});
}

/**
 * Retrieves the content of metadata tags.
 * @param {string} name The metadata name (or property)
 * @param {Document} doc Document object to query for metadata. Defaults to the window's document
 * @returns {string} The metadata value(s)
 */
function getMetadata(name, doc = document) {
	const attr = name && name.includes(':') ? 'property' : 'name';
	const meta = [...doc.head.querySelectorAll(`meta[${attr}="${name}"]`)]
		.map((m) => m.content)
		.join(', ');
	return meta || '';
}

/**
 * Returns a picture element with webp and fallbacks
 * @param {string} src The image URL
 * @param {string} [alt] The image alternative text
 * @param {boolean} [eager] Set loading attribute to eager
 * @param {Array} [breakpoints] Breakpoints and corresponding params (eg. width)
 * @returns {Element} The picture element
 */
function createOptimizedPicture(
	src,
	alt = '',
	eager = false,
	breakpoints = [
		{ media: '(min-width: 600px)', width: '2000' },
		{ width: '750' },
	],
) {
	const url = new URL(src, window.location.href);
	const picture = document.createElement('picture');
	const { pathname } = url;
	const ext = pathname.substring(pathname.lastIndexOf('.') + 1);

	// webp
	breakpoints.forEach((br) => {
		const source = document.createElement('source');
		if (br.media) source.setAttribute('media', br.media);
		source.setAttribute('type', 'image/webp');
		source.setAttribute(
			'srcset',
			`${pathname}?width=${br.width}&format=webply&optimize=medium`,
		);
		picture.appendChild(source);
	});

	// fallback
	breakpoints.forEach((br, i) => {
		if (i < breakpoints.length - 1) {
			const source = document.createElement('source');
			if (br.media) source.setAttribute('media', br.media);
			source.setAttribute(
				'srcset',
				`${pathname}?width=${br.width}&format=${ext}&optimize=medium`,
			);
			picture.appendChild(source);
		} else {
			const img = document.createElement('img');
			img.setAttribute('loading', eager ? 'eager' : 'lazy');
			img.setAttribute('alt', alt);
			picture.appendChild(img);
			img.setAttribute(
				'src',
				`${pathname}?width=${br.width}&format=${ext}&optimize=medium`,
			);
		}
	});

	return picture;
}

/**
 * Set template (page structure) and theme (page styles).
 */
function decorateTemplateAndTheme() {
	const addClasses = (element, classes) => {
		classes.split(',').forEach((c) => {
			element.classList.add(toClassName(c.trim()));
		});
	};
	const template = getMetadata('template');
	if (template) addClasses(document.body, template);
	const theme = getMetadata('theme');
	if (theme) addClasses(document.body, theme);
}

/**
 * Wrap inline text content of block cells within a <p> tag.
 * @param {Element} block the block element
 */
function wrapTextNodes(block) {
	const validWrappers = [
		'P',
		'PRE',
		'UL',
		'OL',
		'PICTURE',
		'TABLE',
		'H1',
		'H2',
		'H3',
		'H4',
		'H5',
		'H6',
	];

	const wrap = (el) => {
		const wrapper = document.createElement('p');
		wrapper.append(...el.childNodes);
		el.append(wrapper);
	};

	block.querySelectorAll(':scope > div > div').forEach((blockColumn) => {
		if (blockColumn.hasChildNodes()) {
			const hasWrapper =
				!!blockColumn.firstElementChild &&
				validWrappers.some(
					(tagName) => blockColumn.firstElementChild.tagName === tagName,
				);
			if (!hasWrapper) {
				wrap(blockColumn);
			} else if (
				blockColumn.firstElementChild.tagName === 'PICTURE' &&
				(blockColumn.children.length > 1 || !!blockColumn.textContent.trim())
			) {
				wrap(blockColumn);
			}
		}
	});
}

/**
 * Decorates paragraphs containing a single link as buttons.
 * @param {Element} element container element
 */
function decorateButtons(element) {
	element.querySelectorAll('a').forEach((a) => {
		a.title = a.title.trim() || a.textContent.trim();
		if (a.href !== a.textContent) {
			const up = a.parentElement;
			const twoup = a.parentElement.parentElement;
			if (!a.querySelector('img')) {
				if (
					up.childNodes.length === 1 &&
					(up.tagName === 'P' || up.tagName === 'DIV')
				) {
					a.className = 'button'; // default
					up.classList.add('button-container');
				}
				if (
					up.childNodes.length === 1 &&
					up.tagName === 'STRONG' &&
					twoup.childNodes.length === 1 &&
					twoup.tagName === 'P'
				) {
					a.className = 'button primary';
					twoup.classList.add('button-container');
				}
				if (
					up.childNodes.length === 1 &&
					up.tagName === 'EM' &&
					twoup.childNodes.length === 1 &&
					twoup.tagName === 'P'
				) {
					a.className = 'button secondary';
					twoup.classList.add('button-container');
				}
			}
		}
	});
}

/**
 * Add <img> for icon, prefixed with codeBasePath and optional prefix.
 * @param {Element} [span] span element with icon classes
 * @param {string} [prefix] prefix to be added to icon src
 * @param {string} [alt] alt text to be added to icon
 */
function decorateIcon(span, prefix = '', alt = '') {
	const iconName = Array.from(span.classList)
		.find((c) => c.startsWith('icon-'))
		.substring(5);

	const existingIcon = [...span.querySelectorAll('img')].find((img) => {
		return img.dataset.iconName === iconName;
	});
	if (existingIcon) return;

	const img = document.createElement('img');
	img.dataset.iconName = iconName;
	img.src = `${window.hlx.codeBasePath}${prefix}/icons/${iconName}.svg`;
	img.alt = alt;
	img.loading = 'lazy';
	span.append(img);
}

/**
 * Add <img> for icons, prefixed with codeBasePath and optional prefix.
 * @param {Element} [element] Element containing icons
 * @param {string} [prefix] prefix to be added to icon the src
 */
function decorateIcons(element, prefix = '') {
	const icons = [...element.querySelectorAll('span.icon')];
	icons.forEach((span) => {
		decorateIcon(span, prefix);
	});
}

/**
 * Decorates all sections in a container element.
 * @param {Element} main The container element
 */
function decorateSections(main) {
	main.querySelectorAll(':scope > div').forEach((section) => {
		const wrappers = [];
		let defaultContent = false;
		[...section.children].forEach((e) => {
			if (e.tagName === 'DIV' || !defaultContent) {
				const wrapper = document.createElement('div');
				wrappers.push(wrapper);
				defaultContent = e.tagName !== 'DIV';
				if (defaultContent) wrapper.classList.add('default-content-wrapper');
			}
			wrappers[wrappers.length - 1].append(e);
		});
		wrappers.forEach((wrapper) => section.append(wrapper));
		section.classList.add('section');
		section.dataset.sectionStatus = 'initialized';
		section.style.display = 'none';

		// Process section metadata
		const sectionMeta = section.querySelector('div.section-metadata');
		if (sectionMeta) {
			const meta = readBlockConfig(sectionMeta);
			Object.keys(meta).forEach((key) => {
				if (key === 'style') {
					const styles = meta.style
						.split(',')
						.filter((style) => style)
						.map((style) => toClassName(style.trim()));
					styles.forEach((style) => section.classList.add(style));
				} else {
					section.dataset[toCamelCase(key)] = meta[key];
				}
			});
			sectionMeta.parentNode.remove();
		}
	});
}

/**
 * Gets placeholders object.
 * @param {string} [prefix] Location of placeholders
 * @returns {object} Window placeholders object
 */
// eslint-disable-next-line import/prefer-default-export
async function fetchPlaceholdersCore(prefix = 'default') {
	window.placeholders = window.placeholders || {};
	if (!window.placeholders[prefix]) {
		window.placeholders[prefix] = new Promise((resolve) => {
			fetch(`${prefix === 'default' ? '' : prefix}/placeholders.json`)
				.then((resp) => {
					if (resp.ok) {
						return resp.json();
					}
					return {};
				})
				.then((json) => {
					const placeholders = {};
					json.data
						.filter((placeholder) => placeholder.Key)
						.forEach((placeholder) => {
							placeholders[toCamelCase(placeholder.Key)] = placeholder.Text;
						});
					window.placeholders[prefix] = placeholders;
					resolve(window.placeholders[prefix]);
				})
				.catch(() => {
					// error loading placeholders
					window.placeholders[prefix] = {};
					resolve(window.placeholders[prefix]);
				});
		});
	}
	return window.placeholders[`${prefix}`];
}

/**
 * @returns {Promise<{[key: string]: string;}}
 */
async function fetchPlaceholders(prefix = 'default') {
	const paths = window.location.pathname.split('/');
	const language = LANGUAGES.find((l) => l === paths[1]?.toLowerCase());

	if (prefix === 'default') {
		return await fetchPlaceholdersCore(`/${language ?? 'en'}`);
	}
	return await fetchPlaceholdersCore(
		prefix.startsWith('/') ? prefix : `/${prefix}`,
	);
}

/**
 * Updates all section status in a container element.
 * @param {Element} main The container element
 */
function updateSectionsStatus(main) {
	const sections = [...main.querySelectorAll(':scope > div.section')];
	for (let i = 0; i < sections.length; i += 1) {
		const section = sections[i];
		const status = section.dataset.sectionStatus;
		if (status !== 'loaded') {
			const loadingBlock = section.querySelector(
				'.block[data-block-status="initialized"], .block[data-block-status="loading"]',
			);
			if (loadingBlock) {
				section.dataset.sectionStatus = 'loading';
				break;
			} else {
				section.dataset.sectionStatus = 'loaded';
				section.style.display = '';
			}
		}
	}
}

/**
 * Builds a block DOM Element from a two dimensional array, string, or object
 * @param {string} blockName name of the block
 * @param {*} content two dimensional array or string or object of content
 */
function buildBlock(blockName, content) {
	const table = Array.isArray(content) ? content : [[content]];
	const blockEl = document.createElement('div');
	// build image block nested div structure
	blockEl.classList.add(blockName);
	table.forEach((row) => {
		const rowEl = document.createElement('div');
		row.forEach((col) => {
			const colEl = document.createElement('div');
			const vals = col.elems ? col.elems : [col];
			vals.forEach((val) => {
				if (val) {
					if (typeof val === 'string') {
						colEl.innerHTML += val;
					} else {
						colEl.appendChild(val);
					}
				}
			});
			rowEl.appendChild(colEl);
		});
		blockEl.appendChild(rowEl);
	});
	return blockEl;
}

/**
 * Loads JS and CSS for a block.
 * @param {Element} block The block element
 */
async function loadBlock(block) {
	const status = block.dataset.blockStatus;
	if (status !== 'loading' && status !== 'loaded') {
		block.dataset.blockStatus = 'loading';
		const { blockName } = block.dataset;
		try {
			const decorationComplete = new Promise((resolve) => {
				(async () => {
					try {
						const mod = await import(
							`${window.hlx.codeBasePath}/blocks/${blockName}/${blockName}.js`
						);
						if (mod.default) {
							await mod.default(block);
						}
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log(`failed to load module for ${blockName}`, error);
					}
					resolve();
				})();
			});
			await Promise.all([decorationComplete]);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`failed to load block ${blockName}`, error);
		}
		block.dataset.blockStatus = 'loaded';
	}
	return block;
}

/**
 * Loads JS and CSS for all blocks in a container element.
 * @param {Element} main The container element
 */
async function loadBlocks(main) {
	updateSectionsStatus(main);
	const blocks = [...main.querySelectorAll('div.block')];
	for (let i = 0; i < blocks.length; i += 1) {
		// eslint-disable-next-line no-await-in-loop
		await loadBlock(blocks[i]);
		updateSectionsStatus(main);
	}
}

/**
 * Decorates a block.
 * @param {Element} block The block element
 */
function decorateBlock(block) {
	const shortBlockName = block.classList[0];
	if (shortBlockName) {
		block.classList.add('block');
		block.dataset.blockName = shortBlockName;
		block.dataset.blockStatus = 'initialized';
		wrapTextNodes(block);
		const blockWrapper = block.parentElement;
		blockWrapper.classList.add(`${shortBlockName}-wrapper`);
		const section = block.closest('.section');
		if (section) section.classList.add(`${shortBlockName}-container`);
	}
}

/* custom function if section metadata has "author" style class */
function decorateSectionFromMetadata(main) {
	const authorSection = main.querySelector('.section.author');
	if (authorSection) {
		const wrapper = authorSection.querySelector('.default-content-wrapper');
		const newDiv = document.createElement('div');
		newDiv.classList.add('content-wrapper');

		const paragraphs = wrapper.querySelectorAll('p');
		paragraphs.forEach((paragraph) => {
			if (
				!paragraph.querySelector('picture') &&
				!paragraph.querySelector('img')
			) {
				newDiv.appendChild(paragraph);
			}
		});
		wrapper.appendChild(newDiv);
	}
}

/**
 * Decorates all blocks in a container element.
 * @param {Element} main The container element
 */
function decorateBlocks(main) {
	main.querySelectorAll('div.section > div > div').forEach(decorateBlock);
}

/**
 * Loads a block named 'header' into header
 * @param {Element} header header element
 * @returns {Promise}
 */
async function loadHeader(header) {
	const headerBlock = buildBlock('header', '');
	header.append(headerBlock);
	decorateBlock(headerBlock);
	return loadBlock(headerBlock);
}

/**
 * Loads a block named 'footer' into footer
 * @param footer footer element
 * @returns {Promise}
 */
async function loadFooter(footer) {
	const footerBlock = buildBlock('footer', '');
	footer.append(footerBlock);
	decorateBlock(footerBlock);
	return loadBlock(footerBlock);
}

/**
 * Load LCP block and/or wait for LCP in default content.
 * @param {Array} lcpBlocks Array of blocks
 */
async function waitForLCP(lcpBlocks) {
	const block = document.querySelector('.block');
	const hasLCPBlock = block && lcpBlocks.includes(block.dataset.blockName);
	if (hasLCPBlock) await loadBlock(block);

	const lcpCandidate = document.querySelector('main img');

	await new Promise((resolve) => {
		if (lcpCandidate && !lcpCandidate.complete) {
			lcpCandidate.setAttribute('loading', 'eager');
			lcpCandidate.addEventListener('load', resolve);
			lcpCandidate.addEventListener('error', resolve);
		} else {
			resolve();
		}
	});
}

init();

export {
	buildBlock,
	createOptimizedPicture,
	decorateBlock,
	decorateBlocks,
	decorateButtons,
	decorateIcons,
	decorateSections,
	decorateSectionFromMetadata,
	decorateTemplateAndTheme,
	fetchPlaceholders,
	getMetadata,
	loadBlock,
	loadBlocks,
	loadCSS,
	loadFooter,
	loadHeader,
	loadScript,
	readBlockConfig,
	sampleRUM,
	setup,
	toCamelCase,
	toClassName,
	updateSectionsStatus,
	waitForLCP,
	wrapTextNodes,
};
